// Функция ymaps.ready() будет вызвана, когда
// загрузятся все компоненты API, а также когда будет готово DOM-дерево.
window.addEventListener('load', () => {
    if(typeof ymaps !== 'undefined') {
        ymaps.ready(init);
    }
})

function init() {
    const map = document.getElementById('google-map')

    getJSON(getLocale(), function (err, data) {
        // Создание карты.
        const mapContacts = new ymaps.Map(map, {
            // Координаты центра карты (широта, долгота). Только для начальной инициализации, затем заменятся метками и их координатами
            center: getMapCenter(map, data),
            // Уровень масштабирования. Допустимые значения:
            // от 0 (весь мир) до 19.
            zoom: map.dataset.zoom || 6,
            // элементы управления (ничего, если оставить пустым)
            // https://yandex.ru/dev/maps/archive/doc/jsapi/2.0/ref/reference/map.control.Manager.html#map.control.Manager__param-controls
            controls: ['zoomControl'],
    
        })
        // Увеличение карты при скроле
        mapContacts.behaviors.disable('scrollZoom');
        // Добавление меток (массив обьектов "marks" передается в шаблоне)
        if(typeof data != 'undefined') {
            createMarks(data, mapContacts)
        }

    });
}

// создание меток из массива
function createMarks(arr, map) {
    if(arr.length > 0) {
        arr.forEach(el => {
            map.geoObjects.add(mark(el))
        });
    } else {
        throw new Error('Marks array empty');
    }
}
// обьект создания метки
function mark(el) {
    return new ymaps.Placemark([el.lat, el.lng], {
        hintContent: el.title || '',
        balloonContent: placemarkContent(el),
    }, {
        // Опции.
        // Необходимо указать данный тип макета.
        iconLayout: 'default#image',
        // Своё изображение иконки метки.
        iconImageHref: el.placemark,
        // Размеры метки.
        iconImageSize: [45, 41],
        // Смещение левого верхнего угла иконки относительно
        // её "ножки" (точки привязки).
        // iconImageOffset: [-5, -38]
    })
}

function placemarkContent(point) {
    let template = `
        <div class='map-point-info'>
            <div class='info-holder'>
                <span class='point-title'>${point.title}</span>
                <span class='point-text'>${point.text}</span>
                <span class='point-contact'><b>${point.contact}</b></span>
                <span class='point-address'>${point.address}</span>
                ${getPhone(point)}
                ${getEmail(point)}
            </div>
        </div>`

    function getPhone(point) {
        if(point.phone) {
            return `
                <br>
                <span class='point-phone-wrap'>
                    <span class='map-contact-title'>Mob: </span>
                    <a href='tel:${point.phone}' class='point-phone'>${point.phone}</a>
                </span>`
        }
        return ''
    }

    function getEmail(point) {
        if(point.email) {
            return `
                <br>
                <span class='point-phone-wrap'>
                    <span class='map-contact-title'>E-mail: </span> 
                    <span class='point-email'>
                        <a href='mailto:${point.email}'>${point.email}</a>
                    </span>
                </span>`
        }
        return ''
    }
    return template;
}


const getJSON = function (url, callback) {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.responseType = 'json';
    xhr.onload = function () {
        const status = xhr.status;
        if (status === 200) {
            callback(null, xhr.response);
        } else {
            callback(status, xhr.response);
        }
    };
    xhr.send();
};

function getLocale() {
    let url = '/contacts/json'
    let locale = document.documentElement.dataset.locale

    if(locale) {
        if(locale === 'ru') {
            return url
        }

        return `/${locale}${url}`
    }
    return url
}

function getMapCenter(map, data) {
    let center = map.dataset.center

    if(center) {
        center = JSON.parse(center)
        return [center.lat, center.lng]
    }
    return getMapCenterByCoord(data)
}

// если нужно определить центр карты по меткам
function getMapCenterByCoord(arr) {
    if(typeof arr !== 'undefined') {
        let x = 0, y = 0;
        if(arr.length > 0) {
            arr.forEach((el, key) => {
                x += parseFloat(el.lat)
                y += parseFloat(el.lng)
            })

            return [getCoord(x), getCoord(y)];
        } else {
            throw new Error('Marks array empty');
        }
    } else {
        // Некие базовые координаты
        return [57.997855, 50.911117]
    }

    function getCoord(coord) {
        return coord = (coord / arr.length).toFixed(7)
    }
}