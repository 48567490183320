const $catalogSlick = $('[data-categories-list-tree-slick]');
const $categoriesList = $('.categories-list');

if ($catalogSlick.length) {
    $catalogSlick.find('.lazy').each(function () {
        const el = $(this);
        const url = el.attr("data-src");
        el.attr("src", url);
        el.removeClass("lazy");
    });

    const $catalogSlickClone = $catalogSlick.clone();
    const $catalogSlickSlider = $catalogSlick
        .clone()
        .addClass('categories-list-tree-slick')
        .slick({
            slidesToShow: 5,
            infinite: true,
            // autoplay: true,
            autoplaySpeed: 5000,
            responsive: [
                {
                    breakpoint: 1478,
                    settings: {
                        slidesToShow: 4,
                        arrows: true,
                        dots: false
                    }
                },
                {
                    breakpoint: 998,
                    settings: {
                        slidesToShow: 3,
                        arrows: true,
                        dots: false
                    }
                },
                {
                    breakpoint: 782,
                    settings: {
                        slidesToShow: 1,
                        arrows: true,
                        dots: false
                    }
                },
            ]
        });

    const windowHeight = $(window).height();

    $(document).on('scroll', function() {
        $catalogSlickSlider.each(function() {
            const self = $(this),
                height = self.offset().top + self.height();
            if ($(document).scrollTop() + windowHeight >= height) {
                self.slick('slickPlay');
            }
        });
    });

    if(!window.matchMedia('(max-width: 782px)').matches){
        $categoriesList.find('[data-categories-list-tree-slick]').replaceWith($catalogSlickSlider);
    }

    $(window).on('resize', function () {
        const $catalogSlick = $categoriesList.find('[data-categories-list-tree-slick]');
        if(
            window.matchMedia('(max-width: 782px)').matches
            && $catalogSlick.hasClass('categories-list-tree-slick')
        ) {
            $catalogSlick.replaceWith($catalogSlickClone);
            $catalogSlick.removeClass('categories-list-tree-slick');
            $(document).off('scroll');
        }

        if(
            !window.matchMedia('(max-width: 782px)').matches
            && !$catalogSlick.hasClass('categories-list-tree-slick')
        ) {
            $catalogSlick.replaceWith($catalogSlickSlider);
            $catalogSlick.addClass('categories-list-tree-slick');

            $(document).on('scroll', function() {
                $catalogSlickSlider.each(function() {
                    const self = $(this),
                        height = self.offset().top + self.height();
                    if ($(document).scrollTop() + windowHeight >= height) {
                        self.slick('slickPlay');
                    }
                });
            });
        }
    });
}
