import 'slick-carousel';

let slick;

window.addEventListener('DOMContentLoaded', () => {
    const newsBlock = document.querySelector('[data-news-items]')

    if(!newsBlock) {
        return
    }
    if(!newsBlock.dataset.newsLoadMore) {
        loadNewsOnButton(newsBlock);
        return;
    }
    let check = true

    window.addEventListener('scroll', loadNewsScroll, true)

    function loadNewsScroll() {
        if(!check) {
            return;
        }

        if(newsBlock.dataset.newsLoadMore == '/news?') {
            window.removeEventListener('scroll', loadNewsScroll, true)
            return
        }

        if(newsBlock.getBoundingClientRect().bottom - window.scrollY < window.scrollY) {
            check = false

            get('get', newsBlock.dataset.newsLoadMore)
            .then((data) => {
                appendNews(data, newsBlock)
            })
            .then(() => {
                setTimeout(() => {
                    check = true
                }, 500)
            })
    
        }
    }

    function loadNewsOnButton(parent) {
        const pagerLink = document.querySelector('[data-pagination-link]')
        if (pagerLink) {
            const pagerSpan = pagerLink.querySelector('span')
    
            pagerLink.addEventListener('click', (e) => {
                e.preventDefault();
    
                if(pagerLink.classList.contains('loading')) {
                    return
                }
    
                pagerLink.classList.add('loading')
    
                get('get', pagerLink.href)
                .then((data) => {
                    // да да это именно пагинация
                    const newPagination = appendNews(data, parent, true)
                    const newLink = newPagination.querySelector('[data-pagination-link]')

                    if(!newLink) {
                        parent.parentElement.querySelector('.pagination-wrapper').remove()
                        return
                    }

                    const newSpan = newLink.querySelector('span')
    
                    pagerLink.href = newLink.href
                    pagerSpan.textContent = newSpan.textContent
                    pagerLink.classList.remove('loading')

                })
            })
        }
    }

    function appendNews(data, parent, isButton = false) {
        let pageObject = document.createElement('html')
        pageObject.innerHTML = data.responseText
    
        const newNews = pageObject.querySelector('[data-news-items]');
    
        [...newNews.children].forEach(el => {
            parent.insertAdjacentElement('beforeend', el)
        });
    
        if(isButton) {
            return pageObject.querySelector('.pagination-wrapper')
        } else {
            parent.dataset.newsLoadMore = newNews.dataset.newsLoadMore
        }
    }
}, {once: true})

export function get(method, action) {
    return new Promise(resolve => {
        const xhr = new XMLHttpRequest()
        xhr.open(method, action)
        xhr.send()
        xhr.onload = function() {
            if (xhr.status >= 200 && xhr.status < 400) {
                resolve(xhr)
            } else {
                console.log('Xhr error');
            }
        }
    })
}

const slider_init = () => {
    if (slick) {
        return;
    }
    slick = $('[data-exhibition-slider]').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        arrows: false
    });

};

window.addEventListener('load', e => {
    document.dispatchEvent(new Event('resize'));
    slider_init();
});

window.addEventListener('resize', slider_init);
