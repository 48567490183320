const header = document.querySelector('[data-header]');
const mobileMenu = document.querySelector('[data-mobile-menu]');
if(header){
    const mobileMenuButton = header.querySelector('[data-hamburger]');
    const itemsWithSubItems = mobileMenu.querySelectorAll('[data-has-subitems] .header__menu-opener');

    /**
     * Клик на бургер
     */
    function toggleMenu() {
        mobileMenu.classList.toggle('active');
        mobileMenuButton.classList.toggle('_active')
        document.querySelector('body').classList.toggle('overflow');
        
        if (mobileMenu.classList.contains('active')) {
            setTimeout(() => {
                mobileMenu.querySelector('.menu-holder-mobile-items').classList.add('has-fixed');
            }, 700);
        } else {
            mobileMenu.querySelector('.menu-holder-mobile-items').classList.remove('has-fixed');
        }

    }

    mobileMenuButton.addEventListener('click', e => {
        e.preventDefault();
        toggleMenu();
    });

    /**
     Open subcategory
     */

    itemsWithSubItems.forEach(item => {
        item.addEventListener('click', e => {
            e.preventDefault();
            e.stopImmediatePropagation();
            let subcategory = mobileMenu.querySelector(`[data-subitem="${item.dataset.item}"]`);
            subcategory.classList.toggle('active');
            mobileMenu.querySelector(`.header__menu-item[data-item="${item.dataset.item}"]`).classList.toggle('active');
        })
    });

}

