import { tabInit } from "../vacancy";
window.addEventListener('DOMContentLoaded', () => {
    tabInit('gallery__btn-name', 'gallery__tab')
    tabInit('gallery__btn', 'gallery__tab-body', false, 0, true)

    select('gallery__current-btn', 'gallery__btn-body', 'gallery__btn')
})

window.addEventListener('load', () => {
    loadPlayer()
})
// для мобильной версии
function select(viewClass, bodyClass, itemClass, currentSelect = 0) {
    const view = document.querySelector(`.${viewClass}`);
    const viewBody = document.querySelector(`.${bodyClass}`);
    const viewItem = document.querySelectorAll(`.${itemClass}`);

    if(view && viewBody && viewItem.length > 0) {
        view.addEventListener('click', (event) => {
            // прокрутка к верху элемента за вычетом высоты шапки
            scrollToTop()
            toggle(view, viewClass)
            toggle(viewBody, bodyClass)
        })

        viewItem.forEach((el, key) =>initClick(el, key))

        if(typeof parseInt(currentSelect) == 'number') {
            view.textContent = viewItem[currentSelect].textContent
        }
    }

    function initClick(el, key) {
        el.addEventListener('click', () => {
            // прокрутка к верху элемента за вычетом высоты шапки
            scrollToTop()
            view.textContent = el.textContent
            remove(view, viewClass)
            remove(viewBody, bodyClass)
        })
    }

    function scrollToTop() {
        if(window.innerWidth < 900) {
            window.scrollTo({top: view.parentElement.parentElement.getBoundingClientRect().top + window.scrollY - 100});
            if(view.parentElement.style.position == 'static') {
                view.parentElement.style.position = 'sticky'
            } else {
                view.parentElement.style.position = 'static'
            }
        }
    }

    function add(elem, elemClass) {
        elem.classList.add(`${elemClass}_active`)
    }
    function remove(elem, elemClass) {
        elem.classList.remove(`${elemClass}_active`)
    }
    function toggle(elem, elemClass) {
        elem.classList.toggle(`${elemClass}_active`)
    }
}

// загрузчик для youtube
function loadPlayer() {
    if (typeof(YT) == 'undefined' || typeof(YT.Player) == 'undefined') {

        let tag = document.createElement('script');
        tag.src = "https://www.youtube.com/iframe_api";

        let firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

        window.onYouTubePlayerAPIReady = function() {
            onYouTubePlayerReady();
        };

    } else {
        onYouTubePlayerReady();
    }
}


function onYouTubePlayerReady() {

    const videos = document.querySelectorAll('[data-video]')
    const videoPosters = document.querySelectorAll('[data-poster]')


    if(videos.length > 0 && videos.length == videoPosters.length) {
        videoPosters.forEach((poster, key) => {
            poster.addEventListener('click', () => {
                initializePlayer(key)
                poster.remove()
            }, {once: true})
        })
    }

    function initializePlayer(key) {

        new YT.Player(getElemId(videos[key]), {
            height: '100%',
            width: '100%',
            videoId: getVideoId(videos[key]),
            events: {
                'onReady': onPlayerReady,
            }
        });
    }

    function getElemId(elem) {
        return elem.id
    }

    function getVideoId(elem) {
        return elem.dataset.video
    }

    function onPlayerReady(event) {
        event.target.mute();
        event.target.playVideo();
    }
}
