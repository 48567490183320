$(document).ready(function () {


    let lazyloadImages = $(".lazy");
    let lazyloadImagesBg = $(".lazy-bg");
    const countToLoad = lazyloadImagesBg.length;
    let loadedBgImages = 0;
    console.log(countToLoad);
    function lazyload() {
        lazyloadImages.each(function () {
            const el = $(this);
            const url = el.attr("data-src");
            el.attr("src", url);
            el.removeClass("lazy");
            lazyloadImages = $(".lazy");
        });

    }

    function lazyloadbg() {

        lazyloadImagesBg.each(function () {
            const el = $(this);
            if(el.data('style')){
                el.attr('style', el.data('style'))
                loadImage(el)
            }
            el.removeClass("lazy-bg");
            lazyloadImagesBg = $(".lazy-bg");
        });
    }



    function loadImage(element) {
        const img = new Image();
        const style = element.currentStyle || window.getComputedStyle(element.get(0), false),
            src = style.backgroundImage.slice(4, -1).replace(/"/g, "");
        img.src = src;
        img.onload = function () {
            loadedBgImages++;
            onImageLoad();

        };
        img.error = function () {
            loadedBgImages++;
            onImageLoad();
        }
    }

    function onImageLoad() {
        if(loadedBgImages == countToLoad){
            $(window).trigger('images_loaded');
        }
    }


    let $smallBg = $('[data-small-bg]');


    function replaceSrcImages() {
        const $windowWidth = $(window).width();
        $.each($smallBg, function () {
            const $this = $(this);
            let bg = $this.data('original');
            if($windowWidth < 700){
                 bg = $this.data('small-bg');
            }
            $this.attr('style', "background-image: url('"+bg+"')");
        });
    }

    replaceSrcImages();
    lazyloadbg();
    lazyload();


})
