const findAllCommunication = function(){
    const communicationSelectElements = document.querySelectorAll('[data-is-communication]');

    communicationSelectElements.forEach(selectElement => {
        selectElement.addEventListener('change', handleChangeCommunication);
    });
}



const handleChangeCommunication = function(event) {

    const selectedValue = event.target.value;
    const selectId = event.target.id;

    console.log(selectId);
    console.log(selectedValue);

    let targetValue = selectedValue;

    // Extract the base part by removing "_communication" using String.prototype.replace()
    const formId = selectId.replace(/_communication$/, '');

    console.log('Selected Value:', selectedValue);
    console.log('Select ID:', selectId);
    console.log('Form ID:', formId);

    if((targetValue === undefined) || ( targetValue === '')){
        targetValue = 'phone';
    }

    const emailDiv = document.querySelector('#' + formId + '_communication_email');
    const phoneDiv = document.querySelector('#' + formId + '_communication_phone');

    const targetDiv = document.querySelector('#' + formId + '_communication_' + targetValue);


    emailDiv.style.display = 'none';
    phoneDiv.style.display = 'none';
    targetDiv.style.display = 'block';

};

document.addEventListener('DOMContentLoaded', () => {
    findAllCommunication();
});