$(function () {
    const $sliders = $('[data-slick-mobile]');

    $sliders.slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        fade: true,
        mobileFirst: true,
        dots: true,
        responsive: [
            {
                breakpoint: 784,
                settings: 'unslick'
            },
            {
                breakpoint: 783,
                settings: {
                    slidesToShow: 1,
                    dots: true
                }
            },
        ]
    });

    $(document).on('click', '[data-open-menu-inner]', function (e) {
       e.preventDefault();
       var $this = $(this);
        $this.closest('li').find('.inner-menu:first').toggleClass('active');
        $this.toggleClass('active');
        $this.closest('a').toggleClass('active');
        $this.closest('li').toggleClass('active');
        return false;
    });
});
