$(function () {
  var isActive = true;
  var $grid;
  function initGrid() {
      $grid = $(".gallery-holder").masonry({
          // options
          itemSelector: ".gallery-item",
          columnWidth: ".gallery-item",
          // percentPosition: true
      });
      $grid.imagesLoaded().progress(function () {
          if (isActive) {
              $grid.masonry("layout");
          }
      });
      isActive = true;
    }
  function sortPhoto() {
    let categoryBtns = document.querySelectorAll("[data-category-name]");
    let categoryPhoto = document.querySelectorAll("[data-photo-category-name]");
    categoryBtns.forEach((btn, key) => {
      btn.addEventListener("click", (event) => {
          event.preventDefault();
          categoryBtns.forEach((b) => {
            b.classList.remove('gallery-sort__btn_active')
          })
          btn.classList.add('gallery-sort__btn_active')

          let btnCategoryAttr = btn.getAttribute("data-category-name");
            categoryPhoto.forEach((photo) => {
                let photoCategoryAttr = photo.getAttribute("data-photo-category-name");
                if(btnCategoryAttr == 'all') {
                  photo.style.height = 'auto';
                } else if (photoCategoryAttr == btnCategoryAttr) {
                    photo.style.height = 'auto';
                  } else {
                    photo.style.height = '0px';
                  }
            });
            $grid.masonry("layout");
            scrollToSelect()
        });
    });
  }
  function scrollToSelect() {
    const container = document.querySelector('.photo-gallery-page')
    if(container) {
      const containerY  = container.getBoundingClientRect().top + window.pageYOffset;
      const fixedMenuHeight = 85;
      scroll({
        top: containerY - fixedMenuHeight,
        behavior: "smooth",
      })
    }
  }
  initGrid();
  sortPhoto();
});
