import 'slick-carousel';
import {respondDown, respondUp} from "../../common/respond";

const $newsItems = $('[data-news-items]');
let slicks = [];

const init = () => {
    if (!$newsItems.hasClass('no-carousel')) {
        $newsItems.each((index, slider) => {
            if (respondDown('medium')) {
                if (!slider.classList.contains('slick-initialized')) {
                    slicks[index] = $(slider).slick({
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: false,
                        mobileFirst: true,
                        dots: true,
                        arrows: false,
                        responsive: [
                            {
                                breakpoint: 784,
                                settings: "unslick"
                            },
                            {
                                breakpoint: 783,
                                settings: {
                                    slidesToShow: 1,
                                }
                            },
                        ]
                    });
                }
            }
        });
    }
};

window.addEventListener('load', e => {
    document.dispatchEvent(new Event('resize'));
    init();
});
window.addEventListener('resize', init);
