import 'slick-carousel';
import '@fancyapps/fancybox';
import '@fancyapps/fancybox/dist/jquery.fancybox.css';
import respond, {breakpoints, respondDown, respondUp} from "../../common/respond";

$('[data-fancybox-review]').fancybox({
    padding: 0,
    helpers: {
        overlay: {
            locked: false
        }
    }
});

$('[data-fancybox-video]').fancybox({
    padding: 0,
    helpers: {
        overlay: {
            locked: false
        }
    }
});
/**
 * Слайдер слева
 */
const sliderFor = $('[data-tests-slider-for]').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    responsive: [
        {
            breakpoint: 784,
            settings: {
                slidesToShow: 1,
            }
        },
        {
            breakpoint: 783,
            settings: {
                slidesToShow: 1,
                dots: true
            }
        },
        {
            breakpoint: 0,
            settings: "unslick"
        },
    ]
});
let $items = $('[data-tests-slider-nav] [data-item]');
$items.each((index, element) => {
    element.addEventListener('click', e => {
        e.preventDefault();
        sliderFor.slick('slickGoTo', element.dataset.item);
        return false
    })
});

/**
 * Штуки на small разрешении
 */
// function small() {
//     const wrapper = document.querySelector('[data-tests-videos-small]');
//     const videos = wrapper.querySelectorAll('[data-tests-video]');
//     const buttonMore = document.querySelector('[data-tests-more]');
//
//     // init
//     videos.forEach((video, index) => {
//         if (index <= 3) {
//             video.classList.add('active');
//         }
//     });
//     buttonMore.addEventListener('click', e => {
//         e.preventDefault();
//         loadMore();
//     });
//
//     let activeVideos = [];
//
//     // Показать еще кнопка
//     function loadMore(button) {
//         activeVideos.length = 0;
//         videos.forEach(video => {
//             if (video.classList.contains('active')) {
//                 activeVideos.push(video);
//             }
//         });
//         let lastVideoId = Number(activeVideos[activeVideos.length - 1].getAttribute('data-tests-video'));
//
//
//         videos.forEach((video, index) => {
//             let videoId = video.getAttribute('data-tests-video');
//             if (videoId == lastVideoId + 1 || videoId == lastVideoId + 2 || videoId == lastVideoId + 3) {
//                 video.classList.add('active');
//             }
//         });
//
//         const hasNext = wrapper.querySelectorAll('[data-tests-video]:not(.active)');
//         if (!hasNext.length) {
//             buttonMore.classList.add('hide');
//         }
//
//     }
// }

// window.addEventListener("load", small);


