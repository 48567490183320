window.addEventListener('DOMContentLoaded', () => {
    tabInit('vacancy__name', 'vacancy__content', 'vacancy__tab-body', false, false, true)
})

export function tabInit(btnClass, tabClass, parentClass, activeNumber = 0, grid, selfClose) {
    let $grid;

    if(parentClass) {
        let parents = document.querySelectorAll(`.${parentClass}`)
        parents.forEach((pBody) => initTab(pBody))
    } else {
        initTab(document)
    }

    function initTab(parent) {

        const btns = parent.querySelectorAll(`.${btnClass}`)
        const tabs = parent.querySelectorAll(`.${tabClass}`)

        if (btns.length > 0 && btns.length === tabs.length) {
            btns.forEach((btn, key) => slug(btns, tabs, btn, key))
            activeTab(btns, tabs)
            if(grid) {
                initGrid()
            }
        }
    }

    function slug(btns, tabs, btn, key) {
        btn.addEventListener('click', () => {
            if(selfClose) {
                toggle(btn, tabs[key])
            } else {
                btns.forEach((el,i) => {
                    remove(el, tabs[i])
                })
                add(btn, tabs[key])
            }

            if(grid) {
                $grid.masonry("layout");
                // прокрутка к верху элемента за вычетом высоты шапки
                window.scrollTo({top: tabs[key].getBoundingClientRect().top + window.scrollY - 200});
            }
        })
    }

    function toggle(btn, tab) {
        btn.classList.toggle(`${btnClass}_active`)
        tab.classList.toggle(`${tabClass}_active`)
    }

    function add(btn, tab) {
        btn.classList.add(`${btnClass}_active`)
        tab.classList.add(`${tabClass}_active`)
    }

    function remove(btn, tab) {
        btn.classList.remove(`${btnClass}_active`)
        tab.classList.remove(`${tabClass}_active`)
    }

    function activeTab(btns, tabs) {
        if(Number.isInteger(parseInt(activeNumber))) {
            add(btns[activeNumber], tabs[activeNumber])
        }
    }

    function initGrid() {
        $grid = $(".gallery__photo-row").masonry({
            // options
            itemSelector: ".gallery__photo-item",
            columnWidth: ".gallery__photo-item",
            // percentPosition: true
        });
        $grid.imagesLoaded().progress(function () {
            $grid.masonry("layout");
        });
    }

}