import LiveEvent from "../../components/live/live";
import Modal from "../../components/modal/modal";
import { phoneFieldRender } from "./international_tel";

phoneFieldRender(false, '#ffffff', false);

new LiveEvent('click', '[data-modal]', function openModal(e) {
    e.preventDefault();
    let link = this;
    let modal = new Modal(this, {
        onAfterStart: () => {
            phoneFieldRender(false, '#ecedec', true)
        },
        // closerText: '',
        onAfterOpen: function () {
            if (link.dataset.pergoal) {
                window.goal(link.dataset.pergoal);
            }
        },
        onFormError: () => {
            phoneFieldRender(false, '#ecedec', true)
        },
        onFormSuccess: function () {
            if (link.dataset.goal) {
                window.goal(link.dataset.goal);
            }
        },
        onBeforeClose: () => {
        },
        onAfterClose: () => {
        },
    });

    //Закрыть модалку п клику за пределами окна
    document.addEventListener('click', event => {
        if(event.target.className === 'modal__layout opened'){
            modal.close();
        }
    });

    return false;
});

new LiveEvent('click', '[data-modal-table]', function openModal(e) {
    e.preventDefault();
    const link = this;
    const modal = new Modal(this, {
        // closerText: '',
        'theme': 'table'

    });
    return false;
});


$(document).ready(function () {
    document.querySelectorAll('[data-auto-modal]').forEach(modal => {
        modal.click();
    });
});

$(function () {
    $(document).on('ajax-form-success', function () {
        $('body, html').addClass('overflow');
        $('#form-holder-success').addClass('success');
    });

    $(document).on('remove.success', function () {
        $('body, html').removeClass('overflow');
        $('#form-holder-success').removeClass('success');
    });

    $(document).on('click', '[data-form-success-closer]', function (e) {
        e.preventDefault();
        $(document).trigger('remove.success');
        return false;
    });
    // Включает\выключает readmore плагин для seo-text.tpl
    // $('[data-short-long-text]').readmore({
    //     moreLink: '<div class="bottom-more"><a class="hover-underline" href="#"><span class="underline-element">Читать дальше</span> &darr;</a></div>',
    //     lessLink: '<a class="hover-underline" href="#"><span class="underline-element">Скрыть</span> &uarr;</a>',
    //     beforeToggle: function (trigger, element, closed) {
    //         element.toggleClass('not-shadow')
    //     },
    //     embedCSS: false,
    // });

    $(document).on('click', '[data-moc-client]', function (e) {
        e.preventDefault();
        const mocId = $(this).data('moc-client');
        moclients.show(mocId);
        return false;
    });

    $(document).on('click', 'form button', function (e) {
        setTimeout(() => {$(this).prop('disabled', true);}, 10);
        setTimeout(() => {$(this).prop('disabled', false);}, 7000);
    });
});
