let current_not_liquid_product_amount = 1;

window.addEventListener('DOMContentLoaded', () => {
    amount_counter();
    buy();
    slider_init();
});

function amount_counter(){
    const amount_obj = document.querySelector('[data-not-liquid-product-amount]');
    if (amount_obj) {

        const minus = amount_obj.querySelector('.not-liquid-amount__minus');
        const plus = amount_obj.querySelector('.not-liquid-amount__plus');
        const number = amount_obj.querySelector('.not-liquid-amount__number');

        let max_amount = amount_obj.dataset.notLiquidProductAmount;


        current_not_liquid_product_amount = (number.innerHTML*1);

        plus.addEventListener('click', (e) => {
            e.preventDefault();
            console.log(current_not_liquid_product_amount);
            console.log(max_amount);
            if(current_not_liquid_product_amount <= (max_amount-1)){
                current_not_liquid_product_amount = current_not_liquid_product_amount + 1;
                number.innerHTML = ''+current_not_liquid_product_amount;
            }
        });

        minus.addEventListener('click', (e) => {
            e.preventDefault();
            if(current_not_liquid_product_amount !== 1){
                current_not_liquid_product_amount = current_not_liquid_product_amount - 1;
                number.innerHTML = ''+current_not_liquid_product_amount;
            }
        });
    }
}

function buy() {
    const buy_button = document.querySelector('[data-not-liquid-product-buy]');
    if (buy_button) {
        buy_button.addEventListener('click', (e) => {
            e.preventDefault();

            let product_id = buy_button.dataset.notLiquidProductBuy;


            alert('id:'+product_id+' amount: '+current_not_liquid_product_amount);
        });
    }
}

function slider_init(){
    let slick = $('[data-not-liquid-product-main-slider]').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        fade: true,
        asNavFor: '[data-not-liquid-product-main-slider-menu]'
    });

    let slick_menu = $('[data-not-liquid-product-main-slider-menu]').slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        asNavFor: '[data-not-liquid-product-main-slider]',
        dots: false,
        centerMode: false,
        focusOnSelect: true,
        arrows: false,
    });
};