
window.goal = function (goal) {
  if (window.Ya && window.Ya._metrika) {
    window.Ya._metrika.counter.reachGoal(goal);
  }
    ga('create', 'UA-139920504-1', 'auto');

    ga('send', {
        hitType: 'event',
        eventAction: goal,
        eventCategory: goal,
        eventLabel: goal
    });

};

$(function () {
    $(document).on('click', '[data-auto-goal]', function () {
        window.goal($(this).data('auto-goal'));
    });
});
