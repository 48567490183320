import LiveEvent from "../../components/live/live";

new LiveEvent('click', '[data-scroll-link], .scroll-link', function scrollLink(e) {
    let selector = this.dataset.selector ? this.dataset.selector : this.getAttribute('href');
    selector = selector.replace(/^\//, "")
    if (!selector.startsWith('#')) {
        return;
    }
    const target = document.querySelector(selector);
    if (target) {
        e.preventDefault()
        let offset = -120
        offset += target.dataset.scrollOffset ? +target.dataset.scrollOffset : 0
        scrollTo(target, offset)
    }
    const $menu = $('[data-mobile-menu].active');
    if ($menu.length) {
        $('[data-mobile-menu]').removeClass('active');
    }
    return false;
});

window.addEventListener('load', function () {
    const selector = location.hash
    const target = document.querySelector(selector);
    if (target) {
        let offset = -120
        offset += target.dataset.scrollOffset ? +target.dataset.scrollOffset : 0
        scrollTo(target, offset)
    }
})

new LiveEvent('click', '[data-toggle-link], .toggle-link', function toggleLink(e) {
    e.preventDefault();
    let target = document.querySelector(this.dataset.selector);
    target.classList.toggle(this.dataset.toggle);
});

const toggleClassSelector = '[data-toggle-class]';
$(toggleClassSelector).on('click', function (e) {
    e.preventDefault();
    const $this = $(this);
    const $container = $($this.data('toggle-container'));
    if ($this.data('toggle-self')) {
        $(toggleClassSelector).removeClass('active');
        $this.addClass('active');
    }
    if ($this.data('back-toggle')) {
        $($this.data('back-toggle')).removeClass('active');
    }
    $container.toggleClass('active');
    return false;
});

$(document).on('click', '[data-click]', function (e) {
    e.preventDefault();
    const container = $(this).data('click');
    $(container).trigger('click');
    return false;
});

function scrollTo(target, offset = 0)
{
    const viewportOffset = target.getBoundingClientRect();
    const top = (window.pageYOffset || document.documentElement.scrollTop) - (document.documentElement.clientTop || 0);
    window.scrollTo({
        top: top + viewportOffset.top + offset,
        behavior: 'smooth'
    })
}

