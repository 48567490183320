require('../../components/sticky/sticky');
import SimpleBar from 'simplebar'

$(function () {
    $('[data-main-slider-product]').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        fade: true,
        asNavFor: '[data-nav-slider]',
        responsive: [
            {
                breakpoint: 783,
                settings: {
                    slidesToShow: 1,
                    dots: true,
                    arrows: false,
                    adaptiveHeight: true,
                }
            },
        ]
    });
    const $nav = $('[data-nav-slider]');
    const $slides = $nav.find('.image-slide');
    const showCount = $slides.length > 4 ? 4 : $slides.length;

    $nav.slick({
        asNavFor: '[data-main-slider-product]',
        dots: true,
        focusOnSelect: true,
        slidesToShow: showCount,
        arrows: false,
        responsive: [
            {
                breakpoint: 999,
                settings: {
                    vertical: true,
                }
            }
        ]
    });

    $('[data-fancybox-product]').fancybox({
        padding: 0,
    });

    $('[data-slick-mobile-only]').slick({
        mobileFirst: true,
        dots: true,
        arrows: false,
        adaptiveHeight: true,
        responsive: [
            {
                breakpoint: 784,
                settings: 'unslick'
            },
        ]
    });

    const $tables = $('.block-container').find('table');
    //
    setTimeout(()=>{
        $.each($tables, (index, table)=>{
            const $table = $(table);
            const $parent = $table.closest('.block-container');
            const $tableWidth = $table.width();
            const $parentWidth = $parent.width();
            const needAddFullView = $tableWidth - $parentWidth  > 1;


            if(needAddFullView && $table.closest('.tables-full-width').length === 0){
                const id = "table-view-" + index;
                const $link  = "<div class='table-full-width-wrapper'><a class='table-full-width' data-modal-table href='#"+id+"'><span class='icon'></span></a></div";
                const $wrapper = $('<div class="wrapper-table"><div id="'+id+'" class="wrapper-table-view"></div></div>');
                $table.wrap($wrapper);
                $table.closest('[data-parent-change-orientation]').append($link);
            }else{
                const  $link_change_orientation = $parent.find('.change-orientation.small-only-show');
                const $wrapper = $('<div class="chars-table-wrapper"><div class="chars-table-wrapper-overflow"></div></div>');
                $table.wrap($wrapper);
                $table.closest('.chars-table-wrapper').append($link_change_orientation);
            }

        });
    },500);

    $.each($('[data-table-bar]'), function () {
        new SimpleBar(this);
    });

});
