const $newsImagesSlick = $('[data-news-view-slick]');

if ($newsImagesSlick.length) {
    const count = $newsImagesSlick[0].getAttribute('data-news-view-slick');
    $newsImagesSlick.slick({
        slidesToShow: 3,
        infinite: true,
        responsive: [
            {
                breakpoint: 783,
                settings: {
                    slidesToShow: 1,
                    arrows: false,
                    dots: true
                }
            },

        ]
    });

}
window.addEventListener('load', () => {
    window.dispatchEvent(new Event('resize'))
});

$(function () {
    $('[data-news-view-mobile-slick]').slick({
        slidesToShow: 1,
        dots: true,
        mobileFirst: true,
        responsive: [
            {
                breakpoint: 783,
                settings: "unslick"
            },

        ]
    })
})
