export const breakpoints = {
    small: {
        from: 0,
        to: 783,
    },
    medium: {
        from: 784,
        to: 999,

    },
    middle: {
        from: 1000,
        to: 1309,
    },
    large: {
        from: 1310,
        to: 1479,

    },
    xl: {
        from: 1480,
        to: 1699,

    },
    xxl: {
        from: 1700,
        to: 9999
    }
};

const respond = (name) => {
    if (breakpoints[name]) {
        return Boolean(window.innerWidth >= breakpoints[name].from && window.innerWidth <= breakpoints[name].to);
    }
    console.warn(`Incorrect breakpoint: ${name}`);
    return false;
};

export const respondDown = (name) => {
    if (breakpoints[name]) {
        return Boolean(window.innerWidth <= breakpoints[name].to);
    }
    console.warn(`Incorrect breakpoint: ${name}`);
    return false;
};



export const respondUp = (name) => {
    if (breakpoints[name]) {
        return Boolean(window.innerWidth >= breakpoints[name].from);
    }
    console.warn(`Incorrect breakpoint: ${name}`);
    return false;
};

export default respond;