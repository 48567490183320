$(function () {
    const $tabs = $('[data-tab-link-contact]');

    $tabs.on('click', function (e) {
       e.preventDefault();
       const $link = $(this);
       const $tab = $($link.data('tab-link-contact'));
       const $all = $($link.data('all'));
        $all.removeClass('active');
        $tab.addClass('active');
        $link.closest('.tabs').find('[data-tab-link-contact]').removeClass('active');
        $link.addClass('active');
        return false;
    });
});
