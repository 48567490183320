window.addEventListener('DOMContentLoaded', () => {
    activate_not_liquid_select();
});

function activate_not_liquid_select(){
    const not_liquid_select = document.querySelector('[data-not-liquid-menu]');
    if (not_liquid_select) {
        not_liquid_select.addEventListener('click', (e) => {
            e.preventDefault();
            const not_liquid_dropdown = document.querySelector('.not-liquid-catalog-menu__dropdown');
            not_liquid_dropdown.classList.toggle('hide');
        });
    }
}