import {respondDown, respondUp} from "../../common/respond";

document.addEventListener('DOMContentLoaded', e => {

    const reviewsBlock = document.querySelector('[data-reviews-block]');
    if (!reviewsBlock) {
        return;
    }
    const loadMoreButtons = reviewsBlock.querySelectorAll('[data-load-more]');
    loadMoreButtons.forEach(button => {
        button.addEventListener('click', e => {
            e.preventDefault();
            loadNext(button);
        });
    });


    function init() {
        let counter = 0;
        reviewsBlock.querySelectorAll('[data-review]').forEach(element => {
            element.classList.remove('active');
            if (respondDown('medium') && counter < 2) {
                element.classList.add('active');
                counter++;
                return;
            }
            if (counter < 3) {
                element.classList.add('active');
                counter++;
            }
        })
    }

    window.addEventListener('load', e => {
        document.dispatchEvent(new Event('resize'));
        init();
    });


    function loadNext(button) {
        let loadItems = 3;
        if (respondDown('medium')) {
            loadItems = 2;
        }
        if (respondDown('small')) {
            return;
        }

        const reviewsActive = reviewsBlock.querySelectorAll('[data-review].active');

        if (reviewsActive.length > 1) {
            let lastItem = reviewsActive[reviewsActive.length - 1];
            // reviewsActive.forEach(el => {
            //     el.classList.remove('active');
            // });

            let nextEl = lastItem.nextElementSibling;
            for (let counter = 0; counter <= loadItems - 1; counter++) {
                if (nextEl) {
                    nextEl.classList.add('active');
                    nextEl = nextEl.nextElementSibling;
                }
            }
            const reviewsNotActive = reviewsBlock.querySelectorAll('[data-review]:not(.active)');
            if (!reviewsNotActive.length) {
                button.classList.add('hide');
            }
        }
    }

    function initSlider() {
        let $slider = $('[data-reviews-slider]');
        if (respondDown('small')) {
            if ($slider[0] && !$slider.hasClass('slick-initialized')) {
                $slider.slick({
                    prevArrow: "",
                    nextArrow: "",
                    infinite: false,
                    dots: true
                });
                return;
            }
        }
        if (respondUp('medium')) {
            if ($slider[0].classList.contains('slick-initialized')) {
                $slider[0].slick.destroy();
            }
        }
    }

    window.addEventListener('load', initSlider);
    window.addEventListener('resize', initSlider);
});
